if (
	window.innerWidth > 1025 &&
	document.querySelector('.home .hero') &&
	localStorage.getItem('scrollAnimation') !== 'done'
) {
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.create({
		trigger: '.home .hero',
		endTrigger: '.home .page-section[data-section="work"]',
		scrub: 0.2,
		start: 'top top',
		end: 'top top',
		revert: false,
		onLeave: () => {
			document.body.classList.add('nav-is-visible');
			localStorage.setItem('scrollAnimation', 'done');
		},
		onEnterBack: () => document.body.classList.remove('nav-is-visible')
	});

	ScrollTrigger.defaults({
		trigger: '.home .hero',
		scrub: 0.4,
		markers: false,
		start: 'top top',
		pin: true
	});

	gsap.from('.home .hero .content-block', {
		scrollTrigger: '.home .hero',
		transform: 'translate( -55vw, 0 )'
	});

	gsap.from('.home .hero-bg', {
		scrollTrigger: '.home .hero',
		width: '100vw',
		height: '83.2%'
	});

	gsap.from('.home .site-nav', {
		scrollTrigger: '.home .hero',
		transform: 'translate(50vw, 0)'
	});

	gsap.from('.home .site-header .container > .site-logo', {
		scrollTrigger: '.home .hero',
		left: '50%',
		width: '900px',
		transform: 'translate(-450px, 0)'
	});

	gsap.to('.home .scrolldown', {
		scrollTrigger: '.home .hero',
		transform: 'translate(-50%, 200%)'
	});
}

// function disableAnimation() {
// 	if (window.scrollY >= ScrollTrigger.end()) {
// 	}
// }

//window.addEventListener('scroll', disableAnimation);

if (
	localStorage.getItem('scrollAnimation') === 'done' &&
	document.querySelector('.scrolldown')
) {
	document.querySelector('.scrolldown').classList.add('hidden');
}
