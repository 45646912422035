var altSlider = new Swiper('[data-col="slider-alt"] .swiper-container', {
	// Optional parameters
	loop: true,
	preloadImages: false,
	lazy: true
});

//altSlider.slideToLoop(1, false, false);

var mainSlider = new Swiper('[data-col="slider-main"] .swiper-container', {
	// Optional parameters
	loop: true,
	preloadImages: false,
	lazy: true,

	// Navigation arrows
	navigation: {
		nextEl: '.slider-nav[data-dir="next"]',
		prevEl: '.slider-nav[data-dir="prev"]'
	},

	on: {
		slideChange: function (e) {
			if (e.activeIndex > e.previousIndex) {
				altSlider.slideNext();
			} else {
				altSlider.slidePrev();
			}
		}
	}
});

var homeSlider = new Swiper('.hero .swiper-container', {
	// Optional parameters
	loop: true,
	preloadImages: false,
	lazy: true,
	effect: 'fade',
	speed: 1000,
	autoplay: {
		delay: 4000,
		disableOnInteraction: false
	}
});

var masterSlider = new Swiper('.master .swiper-container', {
	// Optional parameters
	loop: true,
	preloadImages: false,
	lazy: true,
	effect: 'fade',
	speed: 1000,
	autoplay: {
		delay: 1000,
		disableOnInteraction: false
	}
});

var jobSlider = new Swiper('.job-content .swiper-container', {
	// Optional parameters
	loop: true,
	preloadImages: false,
	lazy: true,
	effect: 'fade',
	speed: 1000,
	autoplay: {
		delay: 1000,
		disableOnInteraction: false
	}
});

var cardSlider = new Swiper('.card-slider .swiper-container', {
	// Optional parameters
	loop: true,
	preloadImages: false,
	lazy: true,
	effect: 'fade',
	speed: 1000,
	autoplay: {
		delay: 1000,
		disableOnInteraction: false
	}
});
