import Modal from './modules/modal.js'; //quite
import objectFitImages from './modules/objectfit.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

import * as Cookie from './modules/cookie.js'; //quite
//import * as Smoothscroll from './modules/smoothscroll.js'; //quite
import * as animation from './modules/animation.js'; //quite
import * as sliders from './modules/sliders.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	objectFitImages();
	mobileMenu();
	showNav();
	new Modal();
}

function showNav() {
	if (window.scrollY >= 100) {
		document.body.classList.add('nav-is-visible');
	} else {
		document.body.classList.remove('nav-is-visible');
	}
}

if (
	!document.body.classList.contains('home') ||
	(document.body.classList.contains('home') &&
		localStorage.getItem('scrollAnimation') === 'done')
) {
	window.addEventListener('scroll', showNav);
}

jQuery(document).ready(function ($) {
	init();

	$('.home').addClass('body-is-visible');

	// Select all links with hashes
	$('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function (event) {
			// On-page links

			if (
				location.pathname.replace(/^\//, '') ==
					this.pathname.replace(/^\//, '') &&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = $(this.hash);
				target = target.length
					? target
					: $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate(
						{
							scrollTop: target.offset().top - 200
						},
						1000,
						function () {
							// Callback after animation
							// Must change focus!
							var $target = $(target);
							$target.focus();
							if ($target.is(':focus')) {
								// Checking if the target was focused
								return false;
							} else {
								$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
								$target.focus(); // Set focus again
							}
						}
					);
				}
			}
		});
});
